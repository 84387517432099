body {
    height: 100vh;
}

#root,
.Main_Wrapper {
    height: 100%;
}

a {
    cursor: pointer;
}

.MuiBox-root {
    border: none !important;
}

.googleLoginButton iframe {
    width: 100% !important;
}

.googleLoginButton iframe #container {
    width: 100% !important;
}

.loginSignUpButton {
    width: 80%;
    color: black;
    background-color: rgb(255 199 2);
}

.loginSignUpButton:hover {
    width: 80%;
    color: rgb(255 199 2);
    background-color: black;
    transition: 200ms;
}

.LoginSignLinkButton,
.ForgotPassword {
    width: 80%;
    color: rgb(255 199 2);
}

.LoginSignLinkButton:hover,
.ForgotPassword:hover {
    width: 80%;
    color: black;
    transition: 200ms;
}

header.tippen_App_header {
    padding: 22px 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FCFCFC;
}

.header_button_div ul.header_button_list {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    align-items: center;
}

.header_dropdown_button {
    border-radius: 50%;
    overflow: hidden;
}

button.create_video_button {
    background: #4E4B66;
    padding: 14px 32px 14px 32px;
    border-radius: 40px;
    color: white;
}

button.create_video_button_1 {
    background: #4E4B66;
}

button.create_video_button_1:hover,
button.create_video_button:hover {
    background: #45425c;
}

.header_dropdown_button img {
    width: 48px;
    height: 48px;
    object-fit: cover;
}

/* Main Content Wrapper */
.Content_Wrapper {
    display: flex;
    padding: 20px;
    gap: 11px;
    justify-content: space-between;
    height: calc(100% - 98px);
    position: relative;
}

.Side_Navbar {
    max-width: 464px;
    flex: 0 0 464px;
    padding: 0 81px 0 100px;
    background-color: rgba(252, 252, 252, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 100%;
    overflow-y: auto;
    transition: all 1s;
}

.Main_Content_box {
    max-width: calc(100% - 475px);
    flex: 0 0 calc(100% - 475px);
    padding-top: 52px;
    height: 100%;
    overflow-y: auto;
    transition: all 1s;
}

.Content_box_inner {
    background-color: #f5f5f5;
}

li.S_ul_li a {
    display: flex;
    gap: 17px;
    color: rgba(20, 20, 43, 1);
    padding: 23px 0;
    border-bottom: 1px solid rgba(160, 163, 189, 1);
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
}

li.S_ul_li a img {
    width: 30px;
}

.userDataTable th,
.userDataTable td {
    background-color: transparent;
}

.UserDataTablePagination {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    padding: 20px 0 20px 0;
}

.UserDataTablePagination button {
    background-color: #e6e4e4;
    padding: 1px 5px;
    border-radius: 4px;
    font-size: 14px;
    width: 40px;
    height: 40px;
}

.UserDataTablePagination button.active {
    background-color: #1b95ff;
    color: white;
}

.UserDataTablePagination button.next,
.UserDataTablePagination button.prev {
    background-color: transparent;
}



.fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
}

.fl-table td,
.fl-table th {
    text-align: center;
    padding: 8px;

}

.fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
    text-wrap: wrap;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 200px;
}

.fl-table thead th {
    color: #14142b;
    background: #ffc702;
}


.fl-table thead th:nth-child(odd) {
    color: #ffffff;
    background: #14142b;
}

.fl-table tr:nth-child(even) {
    background: #F8F8F8;
}

.hamburger-react {
    width: 30px !important;
    height: 30px !important;
    display: flex;
    flex-direction: column;
    gap: 7px;
    justify-content: center;
}

.hamburger-react div {
    width: 100% !important;
    position: unset !important;
}

.hamburger-react div {
    width: 100% !important;
    position: unset !important;
    flex: 0 0 3px;
    max-height: 3px;
}

/* Responsive */

@media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }

    .table-wrapper:before {
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }

    .fl-table thead,
    .fl-table tbody,
    .fl-table thead th {
        display: block;
    }

    .fl-table thead th:last-child {
        border-bottom: none;
    }

    .fl-table thead {
        float: left;
    }

    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }

    .fl-table td,
    .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }

    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }

    .fl-table tbody tr {
        display: table-cell;
    }

    .fl-table tbody tr:nth-child(odd) {
        background: none;
    }

    .fl-table tr:nth-child(even) {
        background: transparent;
    }

    .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tbody td {
        display: block;
        text-align: center;
    }
}

@media screen and (max-width:768px) {

    .Side_Navbar_open {
        max-width: 100% !important;
        flex: 0 0 100% !important;
        width: calc(100% - 40px);
    }

}


.hamburger_span {
    display: none;
}

@media screen and (max-width:1024px) and (min-width:768px) {
    .Side_Navbar_open {
        max-width: 350px !important;
        flex: 0 0 350px !important;
        width: 350px !important;
    }
}

@media screen and (max-width:575px) {
    header.tippen_App_header {
        padding: 13px 17px !important;
    }

    button.create_video_button {
        padding: 8px 16px !important;
    }
}

@media screen and (max-width:1024px) {

    .tippen_logo {
        max-width: 85px;
        height: auto;
    }

    .pricing_outer_div {
        display: grid !important;
    }

    header.tippen_App_header {
        padding: 21px 40px;
    }

    button.create_video_button {
        padding: 5px 10px !important;
        font-size: 12px;
    }

    .header_button_div ul.header_button_list {
        gap: 7px;
    }

    span.hamburger_span {
        display: flex;
        max-width: 50px;
        justify-content: center;
    }

    .Content_Wrapper_open:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #00000085;
        z-index: 1004;
        -webkit-backdrop-filter: blur(1.5px);
        backdrop-filter: blur(1.5px);
    }

    .Side_Navbar_open {
        max-width: 350px !important;
        flex: 0 0 350px !important;
        width: 350px !important;
    }

    .Side_Navbar {
        max-width: 50px;
        flex: 0 0 50px;
        padding: 10px 7px;
        overflow: hidden;
        position: absolute;
        left: 20px;
        transition: all 1s;
        width: 50px;
        z-index: 1005;
    }

    .Main_Content_box {
        max-width: calc(100% - 61px);
        flex: 0 0 calc(100% - 61px);
    }

    .span_item_data {
        display: none;
    }

    .Content_Wrapper {
        justify-content: end;
    }

    .Side_Navbar_open {
        overflow-y: auto !important;
        z-index: 1005;
    }


    .span_item_data_open {
        display: block !important;
    }

    .header_dropdown_button img {
        width: 28px;
        height: 28px;
    }
}

.centerDiv {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0;
    padding: 0;
}


.sec-title {
    position: relative;
    margin-bottom: 70px;
}

.sec-title .title {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 1em;
    color: #ff8a01;
    font-weight: 500;
    background: rgb(247, 0, 104);
    background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
    background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
    background: linear-gradient(to left, rgba(247, 0, 104) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
    color: transparent;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 15px;
}

.sec-title h2 {
    position: relative;
    display: inline-block;
    font-size: 48px;
    line-height: 1.2em;
    color: #1e1f36;
    font-weight: 700;
}

.sec-title .text {
    position: relative;
    font-size: 16px;
    line-height: 28px;
    color: #888888;
    margin-top: 30px;
}

.sec-title.light h2,
.sec-title.light .title {
    color: #ffffff;
    -webkit-text-fill-color: inherit;
}

.pricing-section {
    position: relative;
    padding: 20px 0 5px;
    overflow: hidden;
}

.pricing-section .outer-box {
    max-width: 1100px;
    margin: 0 auto;
}


.pricing-section .row {
    margin: 0 -30px;
}

.pricing-block {
    position: relative;
    padding: 0 30px;
    margin-bottom: 40px;
}

.pricing-block .inner-box {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
    padding: 0 0 30px;
    max-width: 370px;
    margin: 0 auto;
    border-bottom: 20px solid #40cbb4;
}

.pricing-block .icon-box {
    position: relative;
    padding: 50px 30px 0;
    background-color: #40cbb4;
    text-align: center;
}

.pricing-block .icon-box:before {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 75px;
    width: 100%;
    border-radius: 50% 50% 0 0;
    background-color: #ffffff;
    content: "";
}

li.S_ul_li a.active {
    color: #ffc702;
}

.pricing-block .icon-box .icon-outer {
    position: relative;
    height: 150px;
    width: 150px;
    background-color: #ffffff;
    border-radius: 50%;
    margin: 0 auto;
    padding: 10px;
}

.pricing-block .icon-box i {
    position: relative;
    display: block;
    height: 130px;
    width: 130px;
    line-height: 120px;
    border: 5px solid #40cbb4;
    border-radius: 50%;
    font-size: 50px;
    color: #40cbb4;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    transition: all 600ms ease;
}

.pricing-block .inner-box:hover .icon-box i {
    transform: rotate(360deg);
}

.pricing-block .price-box {
    position: relative;
    text-align: center;
    padding: 10px 20px;
}

.pricing-block .title {
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 1.2em;
    color: #222222;
    font-weight: 600;
}

.pricing-block .price {
    display: block;
    font-size: 30px;
    color: #222222;
    font-weight: 700;
    color: #40cbb4;
}


.pricing-block .features {
    position: relative;
    max-width: 200px;
    margin: 0 auto 20px;
}

.pricing-block .features li {
    position: relative;
    display: block;
    font-size: 14px;
    line-height: 30px;
    color: #848484;
    font-weight: 500;
    padding: 5px 0;
    padding-left: 30px;
    border-bottom: 1px dashed #dddddd;
}

.pricing-block .features li:before {
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 16px;
    color: #2bd40f;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    content: "\f058";
    font-family: "Font Awesome 5 Free";
    margin-top: -8px;
}

.pricing-block .features li.false:before {
    color: #e1137b;
    content: "\f057";
}

.pricing-block .features li a {
    color: #848484;
}

.pricing-block .features li:last-child {
    border-bottom: 0;
}

.pricing-block .btn-box {
    position: relative;
    text-align: center;
}

.pricing-block .btn-box a {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    font-weight: 500;
    padding: 8px 30px;
    background-color: #5c80f8;
    border-radius: 10px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 300ms ease;
}

.pricing-block .btn-box a:hover {
    color: #ffffff;
}

.pricing-block .inner-box:hover .btn-box a {
    color: #5c80f8;
    background: none;
    border:1px solid #5c80f8;
    border-color: #5c80f8;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .inner-box {
    border-color: #1d95d2;
}

.pricing-block:nth-child(2) .btn-box a,
.pricing-block:nth-child(2) .icon-box {
    background-color: #1d95d2;
}

.pricing-block:nth-child(2) .inner-box:hover .btn-box a {
    color: #1d95d2;
    background: none;
    border-radius: 0px;
    border-color: #1d95d2;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .price {
    color: #1d95d2;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .inner-box {
    border-color: #ffc20b;
}

.pricing-block:nth-child(3) .btn-box a,
.pricing-block:nth-child(3) .icon-box {
    background-color: #ffc20b;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .price {
    color: #ffc20b;
}

.pricing-block:nth-child(3) .inner-box:hover .btn-box a {
    color: #ffc20b;
    background: none;
    border-radius: 0px;
    border-color: #ffc20b;
}

.pricing_outer_div {
    display: flex;
    justify-content: center;
    align-items: center;

}

.pricing_outer_div .pricing-block {
    width: 100%;
}

.btn-file {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
}

.btn-file__actions {
    margin: 0;
    padding: 0;
}

.btn-file__actions__item {
    padding: 35px;
    color: #d3e0e9;
    cursor: pointer;
    text-decoration: none;
    border: 3px dashed #d3e0e9;
}

.btn-file__actions__item:first-child {
    border-radius: 35px;
}

.btn-file__actions__item:last-child {
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    border-right: 3px dashed #d3e0e9;
}

.btn-file__actions__item:hover,
.btn-file__actions__item:focus {
    color: #636b6f;
    background-color: rgba(211, 224, 233, 0.1);
}

.btn-file__actions__item:hover--shadow,
.btn-file__actions__item:focus--shadow {
    box-shadow: #d3e0e9 0 0 60px 15px;
}

.btn-file__actions__item--shadow {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.btn-file__actions__item--shadow::before {
    content: " ";
    box-shadow: #fff 0 0 60px 40px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: -1;
}

.btn-file__preview {
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    border-radius: 35px;
    background-size: cover;
    background-position: center;
}

.form-group label.attachment {
    width: 100%;
}

.form-group label.attachment .btn-create>a,
.form-group label.attachment .btn-create>div {
    margin-top: 5px;
}

.form-group label.attachment input[type='file'] {
    display: none;
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: .8s;
    animation-timing-function: ease-out;
    z-index: 1001;
}

@keyframes fadeIn {
    0% {

        opacity: 0;
    }

    100% {

        opacity: 1;
    }
}

.password-input {
    transition: all 0.3s ease-in-out;
}

.password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -45%);
    width: 36px;
    height: 40px;
}

.password .monkey,
.password .monkey-hands {
    position: absolute;
    width: 36px;
    height: 100%;
    right: 0;
    top: 0;
    margin: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.password .monkey svg,
.password .monkey-hands svg {
    height: 40px;
    width: 35px;
}

.password .monkey {
    cursor: pointer;
}

.password .monkey::before {
    content: "";
    position: absolute;
    transition: all 0.2s ease;
    width: 3px;
    height: 0px;
    border-radius: 50%;
    top: 25px;
    border-bottom: 3px solid #3C302A;
    left: 50%;
    transform: translate(-50%, 0);
}

.password .monkey-hands {
    z-index: 2;
    perspective: 80px;
    pointer-events: none;
}

.password .monkey-hands svg {
    transition: transform 0.2s ease-in, opacity 0.1s;
    transform-origin: 50% 100%;
}

.password.show .monkey-hands svg {
    transform: perspective(100px) rotateX(-90deg);
    perspective-origin: bottom;
    opacity: var(--opacity, 0);
    transition: transform 0.2s ease, opacity 0.1s 0.1s;
}

.password.show .monkey::before {
    content: "";
    border-radius: 45%;
    width: 9px;
    height: 6px;
    border: 0;
    border-bottom: 2px solid #3C302A;
    top: 21px;
    left: 50%;
    transform: translate(-50%, 0);
}

.spinner_loader div[aria-label="circles-loading"] {
    align-items: center;
    justify-content: center;
    height: 100%;
}

.spinner_loader {
    height: 100vh;
}





.table-responsive .table {
    text-align: left;
    margin-bottom: 40px;
    width: 100%;
}

.table-responsive .table th {
    border-bottom: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 20px;
    text-align: left;
}

.table-responsive .table td {
    border-bottom: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    background: #f1f1f1;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    color: #666666;
    padding: 10px 20px;
}

.table-responsive .table thead th {
    padding: 15px 20px;
    text-align: left;
    background: #ffba00 !important;
}

.table-responsive .table tr th {
    background: #f1f1f1;
}

.adm_table_div {
    overflow-x: auto;
}

/* @media screen and (min-width:768px) { */



.adm_th1 {
    width: 30%;
    min-width: 93px;
}

.adm_srn {
    min-width: 70px;
}

th.adm_th0 {
    width: 11%;
    min-width: 123px;
}

.adm_th2 {
    width: 40%;
    min-width: 100px;
}

.adm_table_div table {
    word-wrap: break-word;
    word-break: break-all;
}

.adm_srn {
    width: 7%;
}

/* } */

@media screen and (max-width:768px) {
    .adm_srn {
        width: 70px;
    }
}

.loader-13:before,
.loader-13:after,
.loader-13 {
    border-radius: 50%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: loader-13 1.8s infinite ease-in-out;
    animation: loader-13 1.8s infinite ease-in-out;
}

.loader-13 {
    color: currentcolor;
    position: relative;
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    top: -1em;
}

.loader-13:before {
    right: 100%;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader-13:after {
    left: 100%;
}

.loader-13:before,
.loader-13:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: inherit;
    height: inherit;
}

@-webkit-keyframes loader-13 {

    0%,
    80%,
    100% {
        box-shadow: 0 1em 0 -1em;
    }

    40% {
        box-shadow: 0 1em 0 -0.2em;
    }
}

@keyframes loader-13 {

    0%,
    80%,
    100% {
        box-shadow: 0 1em 0 -1em;
    }

    40% {
        box-shadow: 0 1em 0 -0.2em;
    }
}

.ResetLoader {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 100vh;
}

img.user_image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}


.iframe_data_inner_div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.FAFAEditclass {
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding-right: 10px;
}

.FAFAEditclass i {
    cursor: pointer;
}

.MainDivMediaLibrary {
    border: 1px solid black;
    padding: 40px;
    border-radius: 53px;
}



@media (max-width: 500px) {
    .MainDivMediaLibrary {
        width: 100%;
        flex: 0 0 100%;
        padding: 10px 0;
    }

    .iframMainDiv {
        height: 550px !important;
        width: 100% !important;
    }
}

@media (min-width: 1430px) {
    .MainDivMediaLibrary {
        width: 46%;
        flex: 0 0 46%;
        border: 1px solid black;
        padding: 40px;
        border-radius: 53px;
    }

}

.empty-state {
    width: 100%;
    background: #ffffff;
    box-shadow: 1px 2px 10px #e1e3ec;
    border-radius: 4px;
}

.empty-state__content {
    padding: 48px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.empty-state__content .empty-state__icon {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    border-radius: 200px;
    justify-content: center;
    background-color: #f7fafc;
    box-shadow: 0px 2px 1px #e1e3ec;
}

.empty-state__content .empty-state__icon img {
    width: 170px;
}

.empty-state__content .empty-state__message {
    color: #38a169;
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 0.85rem;
}

.empty-state__content .empty-state__help {
    color: #a2a5b9;
    font-size: 0.875rem;
}

.credit {
    color: #A2A5B9;
    font-size: 0.75rem;
    text-align: center;
}

.credit a {
    color: #444;
}

.MainDivMediaLibrary {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-device-width: 768px) {
    .tippne_mobile_screens {
        width: 235px !important;
        height: 535px !important;
        margin: 30px auto 0;
    }

    .tippne_screen_clickablebutton {
        width: 170px !important;
    }

    .tippne_mobile_screens .tippne_screen_video {
        width: 100% !important;
        height: 100% !important;
    }
}

.tippen_floating_btn {
    width: 100%;
    height: 100%;
}

#tippen_iframe {
    width: 100%;
    height: 100%;
}

.iframMainDiv {
    height: 675px;
    width: 375px;
}

#dotlottie_id {
    position: absolute;
    bottom: 0;
    display: none;
}

#dotlottie_id_1 {
    position: absolute;
    bottom: 0;
    display: none;
}

.coming_soon::before {
    content: '' !important;
}
.waiting::before {
    content: "\f017" !important;
    color: #9d9d9d !important;
}
.coming_soon {
    font-weight: 700 !important;
    font-size: 20px !important;
}

.copy-btn i:hover {
    transform: scale(1.3);
    color: #1a1a1a;
    cursor: pointer;
}

.dashboard_main_heading {
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.dashboard_main_class {
    padding: 4px 20px;
}

.dashboard_options {
    display: flex;
    justify-content: space-between;
}

.dashboard_main_heading {
    margin-bottom: 10px;
}

.option_div_common_class {
    padding: 3px 10px;
    border: 2px solid #ccc;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.option_div_common_class_upper {
    border: 2px solid #ccc;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.option_div_common_class_upper .option_select {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    background: transparent;
    font-size: 13px;
    align-items: center;
    font-weight: 600;
}

.option_div_common_class .option_button {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 13px;
    font-weight: 600;
}

.option_div_common_class i {
    margin-right: 7px;
}

.option_select:focus {
    border: none;
    outline: none;
}


.right_charts_panel {
    display: flex;
    gap: 20px;
    max-width: calc(67% - 20px);
    flex: 0 0 calc(67% - 20px);
    flex-direction: column;
}

.dashboard_graphs_main_div {
    display: flex;
    gap: 20px;
}

@media (max-width: 400px) {
    .video_sale_circle {
        height: 150px !important;
        width: 150px !important;
    }

    .video_sale_circle::before {
        transform: translate(8px, 48px) rotate(317deg) !important;
    }
}

@media (min-width: 1024px) {
    .main_preview_div_1 {
        width: 375px;
    }
}

@media (max-width: 867px) {
    .dashboard_graphs_main_div {
        flex-direction: column;
    }

    .side_statistics_panel {
        max-width: 100% !important;
    }

    .right_charts_panel {
        max-width: 100% !important;
    }
}


.dashboard_head {
    margin-bottom: 20px;
}

.side_statistics_panel {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 33%;
    flex: 0 0 33%;
}

.rcp_col {
    /* display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: calc(50% - 10px);
    flex: 0 0 calc(50% - 10px); */
    width: 100%;
}

.green_h {
    color: #0AB388;
    font-size: 13px;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
}

body {
    font-family: "Poppins", sans-serif;
}

.Story_name {
    width: 375px;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .Story_name {
        width: 235px !important;
    }
}

h1.sales_percentage {
    font-size: 25px;
    font-weight: 600;
}

.copy-input {
    max-width: 275px;
    width: 100%;
    cursor: pointer;
    background-color: #eaeaeb;
    border: none;
    color: #6c6c6c;
    font-size: 14px;
    border-radius: 5px;
    padding: 15px 45px 15px 15px;
    font-family: 'Montserrat', sans-serif;
}

/* .video_sale_circle {
    border-radius: 50%;
    border: 8px solid #0AB388;
} */

.video_sale_circle::after {
    content: ' ';
    background-color: black;
}

.video_sale_circle::before {
    content: '';
    width: 11px;
    height: 8px;
    background-color: #111111;
    position: absolute;
    left: 0;
    transform: translate(14px, 63px) rotate(317deg);
}

.video_sale_circle::after {
    content: ' ';
    width: 14px;
    height: 6px;
    background-color: #111111;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0) rotate(90deg);
    top: -9px;
}

.video_sale_circle {
    position: relative;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: double 10px transparent;
    background-image: linear-gradient(#111111, #111111), conic-gradient(#05B380, #05B380 64%, #9BF7D9 30%, #9BF7D9);
    background-origin: border-box;
    background-clip: content-box, border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iframe_copyDivOuter {
    background-color: #4e4b66;
    padding: 20px;
    margin: 0 0 40px;
}

.iframe_copyDivInner {
    display: flex;
    gap: 30px;
    color: white;
    flex-wrap: wrap;
}

label.iframe_label {
    font-size: 16px;
    color: white;
    border-bottom: 1px solid #ffffff57;
    width: 100%;
    display: block;
    margin: 0 0 15px;
    padding-bottom: 7px;
}

.iframe_label .spanBg {
    background-color: #ffffff2e;
    padding: 0 4px;
    color: #ffc702;
}

.iframe_copy {
    width: auto;
    position: relative;
}

.iframe_copy .copy-btn {
    position: absolute;
    top: 50%;
    transform: translate(-30px, -50%);
    color: #4e4b66;
}

.iframe_copy .copy-input {
    width: 100%;
    max-width: 100%;
}

.iframe_copyDiv {
    flex: 1;
}


@media screen and (max-width: 675px) {
    .iframe_copyDiv {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .iframe_copyDivInner {
        gap: 10px;
    }
}