.termsAndConditions {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.termsAndConditionsHeading {
    margin-bottom: 30px;
    font-size: 35px;
    font-weight: 700;
}

.termsParagraphIntro {
    margin: 50px 69px 0;
}

.spangleWelcome {
    font-weight: 500;
}

.serviceLeadingSection {
    width: 80%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.sn {
    font-size: 32px;
    padding-right: 5px;
}

.st {
    font-size: 32px;
}

.spl {
    margin-top: 15px;
    margin-bottom: 15px;

}

.serviceInfoContainer {
    position: relative;
    width: 100%;
}

.serviceLead {
    font-weight: 700;
    margin-left: 15px;
    margin-top: 15px;
    margin-block-end: 5px;

}

.serviceDetails {
    margin-left: 15px;
}

.displayNone {
    display: none;
}

.secionLine {
    height: 40px;
    width: 5px;

    position: absolute;
    top: 7px;
}

/*Font Colours below*/
.lightGreen {
    color: rgb(85, 189, 134);
}

.blue {
    color: #2998ff;
}

.orange {
    color: #ff7730;
}

.purple {
    color: #2a0073
}

/*Set the colours for the lines*/

.lineColorGreen {
    background-image: linear-gradient(to right bottom, rgb(85, 189, 134), rgb(11, 103, 92));
}

.lineColorBlue {
    background-image: linear-gradient(to right bottom, #2998ff, #5643fa);
}

.lineColorOrange {
    background-image: linear-gradient(to right bottom, #ffb900, #ff7730);
}

.lineColorPurple {
    background-image: linear-gradient(to bottom right, #b25f88, #2a0073)
}

.closeTerms {
    border-bottom: 1px solid black;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 5px;
}
.contact_info{
    display: flex;
    justify-content: center;
    gap: 20px;
}